$sidebar-width : 250px;
$theme-color : #2A2E49;
$primary-color : #0CBFEB;


$body-bg : #EFEFEF;


$white : #FFFFFF;
$black : #000000;
$red : #FF0101;

$success : #008743;
$pending : #ECA90E;
$failed : #F30000;

$text-color : #030F09; 
$secondary-color : #F8436E;   

$green: #4BAE4F;
$gray-9 : #999999;
$gray-5 : #555555;	
$gray-21 : #212121;	
$gray-8c : #8C8C8C;	
$input-gry-clr : #EDEDED;

$pink : #E42054;
$border-color:#D8D8D8;
//media width
$mac-screen : 1280px;
$width-992 : 992px;
$sidebar-margin-50 : 50px;
